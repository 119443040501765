<template>
  <v-row
    align="center"
    justify="space-between"
    class="text-center px-3"
  >
    <v-col
      cols="12"
      sm="4"
    >
      <base-img
        v-if="objProject.strImgPathLogo"
        :src="require(`@/assets/logos/${objProject.strImgPathLogo}`)"
        min-height="350"
        max-height="350"
        contain
      />
      <base-img
        v-else
        :src="require(`@/assets/logos/logo-kedatech-thumb.png`)"
        min-height="350"
        max-height="350"
        contain
      />
    </v-col>

    <v-col
      cols="12"
      sm="8"
    >
      <component
        :is="m_objTitle.strComponent"
        :v-if="m_objTitle"
        :class="getTextClasses(m_objTitle.eType, ['black--text'])"
      >
        {{ m_objTitle.strText }}
      </component>

      <component
        :is="m_objSubtitle.strComponent"
        :v-if="m_objSubtitle"
        :class="getTextClasses(m_objSubtitle.eType, ['black--text','mb-12'])"
      >
        {{ m_objSubtitle.strText }}
      </component>

      <base-btn
        class="ma-2"
        color="white"
        text-color="black"
        @click="showModal($event)"
      >
        {{ strDetailsButtonLabel }}
      </base-btn>

      <base-btn
        v-if="objProject.bDemo"
        class="ma-2"
        color="black"
        text-color="white"
        :to="{ name: 'KDAPortfolioDemo', params: { strProjectCode: objProject.eProjectCode } }"
      >
        {{ strDemoButtonLabel }}
      </base-btn>
    </v-col>
  </v-row>
</template>

<script>
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'KDAPortfolioProjectsCard',

    mixins: [TextClass],

    props: {
      strDetailsButtonLabel: {
        type: String,
        default: 'See Details',
      },
      strDemoButtonLabel: {
        type: String,
        default: 'Try Demo',
      },
      objProject: {
        type: Object,
        required: true,
      },
    },

    computed: {
      m_objTitle () {
        return {
          strText: this.objProject.strName,
          strComponent: 'p',
          eType: this.eContentType.TITLE_3,
        }
      },
      m_objSubtitle () {
        return {
          strText: this.objProject.strShortDescription,
          strComponent: 'p',
          eType: this.eContentType.SUBTITLE_3,
        }
      },
    },

    methods: {
      showModal () {
        const objShow = {
          key: this.$vnode.key,
          bShow: true,
        }
        this.$emit('showModal', objShow)
      },
    },
  }
</script>
